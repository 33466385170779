import React, {useEffect, useState} from "react";
import "./ReportIssue.css";
import TextField from "@mui/material/TextField";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select,} from "@mui/material";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Button} from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import RichTextEditor from "../../components/RichTextEditor/RichTextEditor";
import EditorState from "draft-js/lib/EditorState";
import * as yup from "yup";
import Typography from "@material-ui/core/Typography";
import {ApiDetails, KEY} from "../../dummyData";
import {convertToRaw} from "draft-js";
import CryptoJS from "crypto-js";

export default function ReportIssue(props) {

    let details = CryptoJS.AES.decrypt(
        localStorage.getItem("tenant_details"),
        KEY
    );

    let originalText = details.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        fetch(ApiDetails + "pegasus/visionary/tenant/app/problemTypes", {
            method: "POST",
            body: JSON.stringify({
                tenant_id: new Map(
                    Object.entries(JSON.parse(originalText))
                ).get("tenant_id"),
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setProblemTypes(data);
                } else {
                    props.snackBar({
                        text: "Failed Fetching Issue Types.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    }, []);
    const postIssue = () => {
        setLoading(true);
        if (selectedProblemType === "") {
            setLoading(false);
            props.snackBar({
                text: "Kindly Select The Type Of Problem.",
                type: "error",
            });
            return;
        }
        if (selectedIssue === "") {
            setLoading(false);
            props.snackBar({
                text: "Kindly Select The Specific Issue.",
                type: "error",
            });
            return;
        }
        fetch(ApiDetails + "pegasus/visionary/tenant/app/add/issue", {
            method: "POST",
            body: JSON.stringify({
                tenant_id: new Map(
                    Object.entries(JSON.parse(originalText))
                ).get("tenant_id"),
                landlord_id: new Map(
                    Object.entries(JSON.parse(originalText))
                ).get("landlord_id"),
                description: JSON.stringify(
                    convertToRaw(editorState.getCurrentContent())
                ),
                unit: new Map(
                    Object.entries(JSON.parse(originalText))
                ).get("house_number"),
                property: new Map(
                    Object.entries(JSON.parse(originalText))
                ).get("property_name"),
                phone_number: new Map(
                    Object.entries(JSON.parse(originalText))
                ).get("phone_number"),
                problem_type: selectedProblemType,
                problem: selectedIssue,
            }),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    setLoading(false);
                    props.snackBar({
                        text: "Issue Logged Successfully",
                        type: "success",
                    });
                } else {
                    setLoading(false);
                    props.snackBar({
                        text: "Failed Fetching Issue Types.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };
    const getIssues = (value) => {
        fetch(ApiDetails + "pegasus/visionary/tenant/app/problemTypes/types", {
            method: "POST",
            body: JSON.stringify({
                problem_type: value,
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    // console.log(data);
                    setIssues(data);
                } else {
                    props.snackBar({
                        text: "Something Went Wrong. Try Again Later",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };

    const [problemTypes, setProblemTypes] = useState([]);
    const [issues, setIssues] = useState([]);
    const [selectedProblemType, setSelectedProblemType] = useState("");
    const [selectedIssue, setSelectedIssue] = useState("");

    //Loading Screen
    let [backDrop, setBackDrop] = useState(
        // <Backdrop
        //     sx={{color: "#fff", zIndex: theme => theme.zIndex.drawer + 1}}
        //     open={true}
        //     onClick={null}
        // >
        //     <CircularProgress color="inherit"/>
        // </Backdrop>
        <></>
    );
    const [loading, setLoading] = React.useState(false);
    const [editorState, setEditorState] = React.useState(() =>
        EditorState.createEmpty()
    );
    const initialValues2 = {
        first_name: new Map(
            Object.entries(JSON.parse(originalText))
        ).get("first_name"),
        last_name: new Map(
            Object.entries(JSON.parse(originalText))
        ).get("last_name"),
        phone_number: new Map(
            Object.entries(JSON.parse(originalText))
        ).get("phone_number"),
        national_id: new Map(
            Object.entries(JSON.parse(originalText))
        ).get("national_id"),
        unit: new Map(Object.entries(JSON.parse(originalText))).get(
            "house_number"
        ),
        property_name: new Map(
            Object.entries(JSON.parse(originalText))
        ).get("property_name"),
    };
    const validationSchema = yup.object().shape({
        phone_number: yup.string().phone("KE", true).required("Required"),
    });
    return (
        <div className="home">
            {backDrop}
            <div
                style={{
                    alignItems: "center",
                    margin: "20px 20px",
                }}
            >
                <h2
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#398DD2",
                    }}
                >
                    Log Issue
                </h2>
                <Formik
                    initialValues={initialValues2}
                    validationSchema={validationSchema}
                    onSubmit={postIssue}
                >
                    {(props) => (
                        <Form noValidate>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Field
                                    as={TextField}
                                    name="first_name"
                                    label="First Name"
                                    variant="outlined"
                                    disabled={true}
                                    error={props.errors.first_name && props.touched.first_name}
                                    helperText={<ErrorMessage name="first_name"/>}
                                    required
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="last_name"
                                    label="Last Name"
                                    disabled={true}
                                    error={props.errors.last_name && props.touched.last_name}
                                    helperText={<ErrorMessage name="last_name"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Field
                                    as={TextField}
                                    name="phone_number"
                                    label="Phone Number"
                                    error={props.errors.phoneNumber && props.touched.phoneNumber}
                                    helperText={<ErrorMessage name="phoneNumber"/>}
                                    required
                                    disabled={true}
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "32%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="national_id"
                                    label="National ID"
                                    type="number"
                                    disabled={true}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "32%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="property_name"
                                    label="Property"
                                    error={
                                        props.errors.property_name && props.touched.property_name
                                    }
                                    helperText={<ErrorMessage name="property"/>}
                                    required
                                    disabled={true}
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "32%"}}
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <FormControl
                                    required
                                    style={{
                                        marginTop: "10px",
                                        width: "100%",
                                    }}
                                    label="Type of Problem"
                                    name="type_of_problem"
                                >
                                    <InputLabel id="demo-simple-select-required-label">
                                        Type of Problem
                                    </InputLabel>
                                    <Select
                                        name="type_of_problem"
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        value={selectedProblemType}
                                        label="Type of Problem"
                                        onChange={(e) => {
                                            setSelectedProblemType(e.target.value);
                                            getIssues(e.target.value);
                                        }}
                                    >
                                        {problemTypes.map((type) => {
                                            let problem = new Map(Object.entries(type));
                                            return (
                                                <MenuItem value={problem.get("id")}>
                                                    <em>{problem.get("problem_type")}</em>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <FormHelperText>Required</FormHelperText>
                                </FormControl>
                                <br/>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {selectedProblemType === "" ? (
                                    <FormControl
                                        required
                                        style={{
                                            marginTop: "10px",
                                            width: "100%",
                                        }}
                                        label="Issue"
                                        name="issue"
                                        disabled={true}
                                    >
                                        <InputLabel id="demo-simple-select-required-label">
                                            Issue
                                        </InputLabel>
                                        <Select
                                            name="type_of_problem"
                                            labelId="demo-simple-select-required-label"
                                            id="demo-simple-select-required"
                                            value={selectedIssue}
                                            label="Issue"
                                            onChange={(e) => {
                                                setSelectedIssue(e.target.value);
                                            }}
                                        >
                                            {issues.map((issue) => {
                                                let problem = new Map(Object.entries(issue));
                                                return (
                                                    <MenuItem value={problem.get("id")}>
                                                        <em>{issue.get("problem")}</em>
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <FormHelperText>Required</FormHelperText>
                                    </FormControl>
                                ) : (
                                    <FormControl
                                        required
                                        style={{
                                            marginTop: "10px",
                                            width: "100%",
                                        }}
                                        label="Issue"
                                        name="issue"
                                    >
                                        <InputLabel id="demo-simple-select-required-label">
                                            Issue
                                        </InputLabel>
                                        <Select
                                            name="type_of_problem"
                                            labelId="demo-simple-select-required-label"
                                            id="demo-simple-select-required"
                                            value={selectedIssue}
                                            label="Issue"
                                            onChange={(e) => {
                                                setSelectedIssue(e.target.value);
                                            }}
                                        >
                                            {issues.map((issue) => {
                                                let problem = new Map(Object.entries(issue));
                                                return (
                                                    <MenuItem value={problem.get("id")}>
                                                        <em>{problem.get("problem")}</em>
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <FormHelperText>Required</FormHelperText>
                                    </FormControl>
                                )}
                            </div>

                            <div
                                style={{
                                    width: "100%",
                                }}
                            >
                                <br/>
                                <Typography>Please provide more information</Typography>
                                <RichTextEditor
                                    richTextValue={editorState}
                                    change={(value) => {
                                        setEditorState(value);
                                    }}
                                />
                            </div>
                            <br/>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "end",
                                    marginTop: "20px",
                                }}
                            >
                                <Button onClick={() => {
                                }}>
                                    <span style={{color: "red"}}>Cancel</span>
                                </Button>
                                <LoadingButton
                                    size="small"
                                    type="submit"
                                    loading={loading}
                                    startIcon={<SendIcon/>}
                                    style={{color: "green"}}
                                    loadingPosition="start"
                                >
                                    Submit
                                </LoadingButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}
