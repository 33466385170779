import React, {useEffect, useState} from "react";
import "./sidebar.css";
import {ChatBubbleOutline, Logout} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {Close, HomeOutlined, ReportOutlined} from "@material-ui/icons";
import logo from "../../assets/react-logo-no-background.svg";
import {useMediaQuery} from "@mui/material";

export default function Sidebar(props) {
    const matches = useMediaQuery("(max-width:1000px)");
    const [display, setDisplay] = useState("none");
    const changeDisplay = (value) => {
        setDisplay(value);
    };

    const [activePage, setActivePage] = useState(
        document.getElementsByClassName("sidebarListItem").item(0)
    );
    useEffect(() => {
        document.getElementsByClassName("sidebarListItem").item(0).className =
            "sidebarListItem active";
        let active = document
            .getElementsByClassName("sidebarListItem active")
            .item(0);
        setActivePage(active);
    }, []);

    const handleClick = (event) => {
        if (matches) {
            setDisplay("none");
        }
        //Remove Previous Active Page ClassName
        activePage.className = "sidebarListItem";

        //Set New Active Page
        setActivePage(event.target);

        //Set New Active Page ClassName
        event.target.className = "sidebarListItem active";
    };
    const logout = (event) => {
        // 👇️ toggle class on click
        localStorage.clear();
        props.refresh(true);
        props.fn();
    };

    return {
        changeDisplay,
        renderSidebar: (
            <>
                {matches === true ? (
                    <>
                        <div
                            className="sidebar"
                            style={{
                                display: display,
                            }}
                        >
                            <div className="sidebarWrapper">
                                <div
                                    className="closeBtn"
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        textAlign: "center",
                                        justifyContent: "right",
                                        marginTop: "5px",
                                        marginRight: "5px",
                                    }}
                                >
                                    <Close
                                        onClick={() => setDisplay("none")}
                                        style={{
                                            fontSize: "200%",
                                            color: "white",
                                        }}
                                    />
                                </div>
                                <div
                                    className="topLeft"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        textAlign: "center",
                                    }}
                                >
                                    <img
                                        style={{
                                            width: "200px",
                                            height: "150px",
                                        }}
                                        src={logo}
                                        alt=""
                                    />
                                </div>
                                <div className="sidebarWrapperTop">
                                    <div className="sidebarMenu">
                                        <h3 className="sidebarTitle">Dashboard</h3>
                                        <ul className="sidebarList">
                                            <Link className="linkItem" to={"/home"}>
                                                <li onClick={handleClick} className="sidebarListItem">
                                                    <HomeOutlined className="sidebarIcon"/>
                                                    <div className={"sidebarPageName"}>Home</div>
                                                </li>
                                            </Link>
                                            <Link className="linkItem" to={"/issue"}>
                                                <li onClick={handleClick} className="sidebarListItem">
                                                    <ReportOutlined className="sidebarIcon"/>

                                                    <div className={"sidebarPageName"}>Report Issue</div>
                                                </li>
                                            </Link>
                                        </ul>
                                    </div>
                                    <div className="sidebarMenu">
                                        <h3 className="sidebarTitle">Notifications</h3>
                                        <ul className="sidebarList">
                                            <Link
                                                style={
                                                    {
                                                        // pointerEvents: "none",
                                                    }
                                                }
                                                className="linkItem"
                                                to={"/messages"}
                                            >
                                                <li onClick={handleClick} className="sidebarListItem">
                                                    <ChatBubbleOutline className="sidebarIcon"/>

                                                    <div className={"sidebarPageName"}>Messages</div>
                                                </li>
                                            </Link>
                                        </ul>
                                    </div>
                                </div>
                                <div className="sidebarWrapperBottom">
                                    <div className="sidebarMenu">
                                        <h3 className="sidebarTitle">Authentication</h3>
                                        <ul className="sidebarList">
                                            <Link className="linkItem" to={"/"}>
                                                <li
                                                    onClick={() => {
                                                        setDisplay("none");

                                                        logout();
                                                    }}
                                                    name="logout"
                                                    className="sidebarListItem"
                                                >
                                                    <Logout className="sidebarIcon"/>

                                                    <div className={"sidebarPageName"}>Logout</div>
                                                </li>
                                            </Link>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="sidebar">
                            <div className="sidebarWrapper">
                                <div
                                    className="topLeft"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        textAlign: "center",
                                    }}
                                >
                                    <img
                                        style={{
                                            width: "200px",
                                            height: "150px",
                                        }}
                                        src={logo}
                                        alt=""
                                    />
                                </div>
                                <div className="sidebarWrapperTop">
                                    <div className="sidebarMenu">
                                        <h3 className="sidebarTitle">Dashboard</h3>
                                        <ul className="sidebarList">
                                            <Link className="linkItem" to={"/home"}>
                                                <li onClick={handleClick} className="sidebarListItem">
                                                    <HomeOutlined className="sidebarIcon"/>
                                                    <div className={"sidebarPageName"}>Home</div>
                                                </li>
                                            </Link>
                                            <Link className="linkItem" to={"/issue"}>
                                                <li onClick={handleClick} className="sidebarListItem">
                                                    <ReportOutlined className="sidebarIcon"/>

                                                    <div className={"sidebarPageName"}>Report Issue</div>
                                                </li>
                                            </Link>
                                        </ul>
                                    </div>
                                    <div className="sidebarMenu">
                                        <h3 className="sidebarTitle">Notifications</h3>
                                        <ul className="sidebarList">
                                            <Link
                                                style={
                                                    {
                                                        // pointerEvents: "none",
                                                    }
                                                }
                                                className="linkItem"
                                                to={"/messages"}
                                            >
                                                <li onClick={handleClick} className="sidebarListItem">
                                                    <ChatBubbleOutline className="sidebarIcon"/>

                                                    <div className={"sidebarPageName"}>Messages</div>
                                                </li>
                                            </Link>
                                        </ul>
                                    </div>
                                </div>
                                <div className="sidebarWrapperBottom">
                                    <div className="sidebarMenu">
                                        <h3 className="sidebarTitle">Authentication</h3>
                                        <ul className="sidebarList">
                                            <Link className="linkItem" to={"/"}>
                                                <li
                                                    onClick={logout}
                                                    name="logout"
                                                    className="sidebarListItem"
                                                >
                                                    <Logout className="sidebarIcon"/>

                                                    <div className={"sidebarPageName"}>Logout</div>
                                                </li>
                                            </Link>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        ),
    };
}
