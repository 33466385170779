import React, {useEffect, useState} from "react";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import "./home.css";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import {loginState} from "../../dummyData";
import {useLocation, useNavigate} from "react-router-dom";
import Arrears from "../../components/arrears/Arrears";
import Transactions from "../../components/transactions/Transactions";

export default function Home(props) {
    const navigate = useNavigate();
    const {state} = useLocation();

    useEffect(() => {

    }, []);


    //Loading Screen
    let [backDrop, setBackDrop] = useState(
        // <Backdrop
        //     sx={{color: "#fff", zIndex: theme => theme.zIndex.drawer + 1}}
        //     open={true}
        //     onClick={null}
        // >
        //     <CircularProgress color="inherit"/>
        // </Backdrop>
        <></>
    );

    let [updateSet] = React.useState(false);

    const [open, setOpen] = React.useState(loginState);

    const [message] = React.useState("");

    const [severity] = React.useState();
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    return (
        <div className="home">
            {backDrop}
            <Stack spacing={2} sx={{width: "100%"}}>
                <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        severity={severity}
                        sx={{width: "100%"}}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>
            <FeaturedInfo snackBar={props.snackBar}/>
            <Arrears snackBar={props.snackBar}/>
            <Transactions snackBar={props.snackBar}/>
        </div>
    );
}
