import React, {useEffect, useRef, useState} from "react";
import "./message.css";
import {SendOutlined} from "@material-ui/icons";
import {ApiDetails, KEY} from "../../dummyData";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import {TextField} from "@material-ui/core";
import CryptoJS from "crypto-js";

export default function Message(props) {

    let details = CryptoJS.AES.decrypt(
        localStorage.getItem("tenant_details"),
        KEY
    );

    let originalText = details.toString(CryptoJS.enc.Utf8);

    //Loading Screen
    const [backDrop, setBackDrop] = useState(
        <Backdrop
            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
            onClick={null}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>
    );
    let [messages, updateMessages] = React.useState([]);

    //Trying To Use UseEffect To Set Component Data
    useEffect(() => {
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/tenant/app/messages/get", {
            method: "POST",
            body: JSON.stringify({
                id: new Map(
                    Object.entries(JSON.parse(originalText))
                ).get("tenant_id"),
            }),
        })
            .then(async (response) => {
                let messages = await response.json();
                // console.log(messages);
                if (response.status === 200) {
                    updateMessages(messages);
                    setBackDrop(<></>);
                } else {
                    props.snackBar({
                        text: "Failed Fetching Details",
                        type: "error",
                    });
                }
            })
            .catch((err) => console.log(err));
    }, []);

    let inputRef = useRef();
    let divRef = useRef();
    useEffect(() => {
        divRef.current.scrollIntoView({behavior: "smooth"});
    }, [messages]);

    const [message, setMessage] = useState();

    const onSubmit = (message) => {
        let tenant_id = new Map(
            Object.entries(JSON.parse(originalText))
        ).get("tenant_id");
        let landlord_id = new Map(
            Object.entries(JSON.parse(originalText))
        ).get("landlord_id");

        //Send Message To tenant
        fetch(ApiDetails + "pegasus/visionary/tenant/app/messages/send", {
            method: "POST",
            body: JSON.stringify({
                sender_id: tenant_id,
                recipient_id: landlord_id,
                message: message,
            }),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    //Get All Messages
                    fetch(ApiDetails + "pegasus/visionary/tenant/app/messages/get", {
                        method: "POST",
                        body: JSON.stringify({
                            id: new Map(
                                Object.entries(JSON.parse(originalText))
                            ).get("tenant_id"),
                        }),
                    })
                        .then(async (response) => {
                            let messages = await response.json();
                            if (response.status === 200) {
                                // console.log(messages);
                                updateMessages(messages);
                            } else {
                                props.snackBar({
                                    text: "Failed Fetching Details",
                                    type: "error",
                                });
                            }
                        })
                        .catch((err) => console.log(err));
                } else {
                    props.snackBar({
                        text: "Failed Sending Message",
                        type: "error",
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <>
            {backDrop}
            <div className="messages">
                <div className={"messageContainer"}>
                    <div className="rightSide">
                        <>
                            <div id="messages" className="chatBox">
                                {messages.map((message, index) => {
                                    // console.log(message);
                                    try {
                                        JSON.parse(message.message);
                                    } catch (e) {
                                        // console.log("here");
                                    }
                                    if (
                                        message.sender_id ===
                                        new Map(
                                            Object.entries(JSON.parse(originalText))
                                        ).get("tenant_id")
                                    ) {
                                        return (
                                            <div className="message my_message">
                                                <p>
                                                    <div
                                                        style={{
                                                            whiteSpace: "pre-wrap",
                                                        }}
                                                    >
                                                        {message.message}
                                                    </div>
                                                    <br/>{" "}
                                                    <span className={"time"}>{message.date_created}</span>
                                                </p>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div className="message frnd_message">
                                                <p>
                                                    <div
                                                        style={{
                                                            whiteSpace: "pre-wrap",
                                                        }}
                                                    >
                                                        {message.message}
                                                    </div>
                                                    <br/>{" "}
                                                    <span className={"time"}>{message.date_created}</span>
                                                </p>
                                            </div>
                                        );
                                    }
                                })}
                                <div ref={divRef}></div>
                            </div>

                            {/*Chat Input*/}
                            <div className="chatbox_input">
                                <div
                                    style={{
                                        paddingLeft: "5px",
                                        width: "100%",
                                    }}
                                >
                                    <TextField
                                        ref={inputRef}
                                        id="filled-textarea"
                                        value={message}
                                        placeholder={"Type a message"}
                                        onChange={(event) => {
                                            setMessage(event.target.value);
                                        }}
                                        multiline
                                        maxRows={1}
                                        variant="outlined"
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                                </div>
                                <SendOutlined
                                    onClick={() => {
                                        // let message = document.getElementById("message").value;
                                        if (message === null || message.toString().trim() === "") {
                                            props.snackBar({
                                                text: "Cannot Send A Blank message",
                                                type: "error",
                                            });
                                            return;
                                        }
                                        onSubmit(message);
                                        setMessage("");
                                    }}
                                    className={"ion-icon"}
                                    style={{
                                        marginRight: "15px",
                                    }}
                                />
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </>
    );
}
