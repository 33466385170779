import "./App.css";
import React, {useEffect} from "react";
import {ApiDetails, KEY, loginState} from "./dummyData";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Stack from "@mui/material/Stack";
import {NotificationContainer, NotificationManager,} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Home from "./pages/Home/Home";
import Snackbar from "@mui/material/Snackbar";
import {Alert} from "@mui/material";
import Sidebar from "./components/sidebar/Sidebar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Topbar from "./components/topbar/Topbar";
import ReportIssue from "./pages/ReportIssue/ReportIssue";
import Message from "./pages/messages/Message";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import CryptoJS from "crypto-js";
import LoginPage from "./components/authentication/LoginPage";

function App() {
    const [open, setOpen] = React.useState(loginState);

    const [backdrop, setBackDrop] = React.useState(null);

    const [message, setMessage] = React.useState("");

    const [severity, setSeverity] = React.useState();
    const [isLoggedIn, setLogin] = React.useState(false);

    const openDialog = () => {
        console.log("Opening Dialog ... ");
    };

    const changeLogin = () => {
        setLogin(true);
        //updateViewTopBar(true);
    };

    const logout = () => {
        setLogin(false);
        localStorage.clear();
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const handleClick = (message) => {
        setMessage(message.text);
        setSeverity(message.type);
        setOpen(true);
    };
    const openBackDrop = (message) => {
        if (message === "open") {
            setBackDrop(
                <div
                    style={{
                        position: "absolute",
                    }}
                >
                    <Backdrop
                        sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                        open={true}
                        onClick={null}
                    >
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                </div>
            );
        } else {
            setBackDrop();
        }
    };

    const {renderSidebar, changeDisplay} = Sidebar();
    const {render, setForceRefresh} = Topbar(changeDisplay);

    const [connectionMade, setConnectionMade] = React.useState(false);

    // SSE
    useEffect(() => {
        const fetchData = async () => {
            try {

                let details = CryptoJS.AES.decrypt(
                    localStorage.getItem("tenant_details"),
                    KEY
                );

                let originalText = details.toString(CryptoJS.enc.Utf8);

                await fetchEventSource(
                    ApiDetails +
                    "pegasus/visionary/sse/" +
                    new Map(
                        Object.entries(JSON.parse(originalText))
                    ).get("tenant_id"),
                    {
                        method: "GET",
                        headers: {
                            Accept: "text/event-stream",
                        },
                        onopen(res) {
                            if (res.ok && res.status === 200) {
                                setConnectionMade(true);
                                // console.log("Connection made ", res);
                            } else if (
                                res.status >= 400 &&
                                res.status < 500 &&
                                res.status !== 429
                            ) {
                                console.log("Client side error ", res);
                            }
                        },
                        onmessage(event) {
                            try {
                                let notifications = Array.from(JSON.parse(event.data));

                                notifications.map((notifi, index) => {
                                    let notification = new Map(Object.entries(notifi));
                                    if (notification.get("type") === "payment") {
                                        NotificationManager.success(
                                            "Payment of " +
                                            notification.get("amount").toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "KSH",
                                            }) + " has been received",
                                            "Payment Received",
                                            3000
                                        );
                                        setTimeout(() => {
                                            window.location.reload(false)
                                        }, 3500);
                                    }
                                });
                            } catch (e) {
                                //Means the SSE message is a keep alive
                                // console.log(event.data);
                            }

                            // console.log(event.data);
                        },
                        onclose() {
                            console.log("Connection closed by the server retrying ... ");
                        },
                        onerror(err) {
                            console.log("There was an error from server", err);
                        },
                    }
                );
            } catch (e) {
            }
        };
        if (!connectionMade) {
            fetchData();
        }
    }, []);


    return (
        <div>
            <NotificationContainer/>
            <Stack spacing={2} sx={{width: "100%"}}>
                <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        severity={severity}
                        sx={{width: "100%"}}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>
            {backdrop}
            <div className="App">
                <div className="cont">
                    <Router>
                        {renderSidebar}
                        {/*<Sidebar*/}
                        {/*  loginState={isLoggedIn}*/}
                        {/*  fn={() => logout()}*/}
                        {/*  dialog={() => {*/}
                        {/*    openDialog();*/}
                        {/*  }}*/}
                        {/*  backrop={(message) => {*/}
                        {/*    openBackDrop(message);*/}
                        {/*  }}*/}
                        {/*  snackBar={(message) => handleClick(message)}*/}
                        {/*/>*/}
                        <div className={"appStyle"}>
                            {render}
                            <Routes>
                                {/*<Route*/}
                                {/*    path="/"*/}
                                {/*    element={*/}
                                {/*        <CustomizedDialogs>*/}
                                {/*            <LoginDialog*/}
                                {/*                snackBar={(message) => handleClick(message)}*/}
                                {/*                fn={() => changeLogin()}*/}
                                {/*                refresh={(value) => {*/}
                                {/*                    setForceRefresh(value);*/}
                                {/*                }}*/}
                                {/*            />*/}
                                {/*        </CustomizedDialogs>*/}
                                {/*    }*/}
                                {/*/>*/}
                                <Route
                                    path="/"
                                    element={
                                        <LoginPage
                                            snackBar={(message) => handleClick(message)}
                                            fn={() => changeLogin()}
                                            refresh={(value) => {
                                                setForceRefresh(value);
                                            }}
                                        />

                                    }
                                />
                                <Route
                                    path="/home"
                                    element={
                                        <Home
                                            loginState={isLoggedIn}
                                            topBar={() => changeLogin()}
                                            snackBar={(message) => handleClick(message)}
                                        />
                                    }
                                />
                                <Route
                                    path="/issue"
                                    element={
                                        <ReportIssue
                                            loginState={isLoggedIn}
                                            topBar={() => changeLogin()}
                                            snackBar={(message) => handleClick(message)}
                                        />
                                    }
                                />
                                <Route
                                    path="/messages"
                                    element={
                                        <Message
                                            loginState={isLoggedIn}
                                            topBar={() => changeLogin()}
                                            snackBar={(message) => handleClick(message)}
                                        />
                                    }
                                />
                            </Routes>
                        </div>
                    </Router>
                </div>
            </div>
        </div>
    );
}

export default App;
