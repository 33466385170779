import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import "./authentication.css";
import {ApiDetails, KEY,} from "../../dummyData";
import LoadingButton from "@mui/lab/LoadingButton";
import logo from "../../assets/react-logo-no-background.svg";
import {TextField} from "@material-ui/core";
import {Login} from "@mui/icons-material";
import {Field, Form, Formik} from "formik";
import CryptoJS from "crypto-js";
import {InputAdornment} from "@mui/material";
import {VisibilityOffOutlined, VisibilityOutlined} from "@material-ui/icons";

export default function LoginPage(props) {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate();

    const onSubmit = (values) => {
        setLoading(true)

        const body =
            {
                ...values,
                "first_name": values.first_name.toString().trim(),
                "account_number": values.account_number.toString().trim()
            };

        // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/tenant/app/login", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    storeUserDetails(data);
                } else if (response.status === 401) {
                   setLoading(false)
                    props.snackBar({
                        text: "Invalid Credentials Provided.",
                        type: "error",
                    });
                } else if (response.status === 400) {
                    setLoading(false)
                    props.snackBar({
                        text: "Invalid Credentials Provided.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };

    const storeUserDetails = (userDetails) => {
        userDetails = {
            ...userDetails,
            copy_of_contract: "",
            inventory_checklist: "",
            photo_of_national_id: "",
            tenant_photo: ""
        }

        let details = CryptoJS.AES.encrypt(
            JSON.stringify(userDetails),
            KEY
        ).toString();

        // console.log(userDetails)
        // return

        // let decrypted = CryptoJS.AES.decrypt(myMap, KEY);
        // var originalText = decrypted.toString(CryptoJS.enc.Utf8);
        // console.log(originalText);

        localStorage.setItem("tenant_details", details);

        navigate("/home");
        props.refresh(true);
        props.snackBar({text: "Login Successful", type: "success"});
        props.fn();
        setLoading(false)
    };
    const [loading, setLoading] = React.useState(false);

    const [type, setType] = useState("password");
    const [icon, setIcon] = useState(<VisibilityOutlined/>);

    function handleClick(value) {
        setLoading(value);
    }

    const initialValues = {
        first_name: "",
        account_number: "",
    };

    localStorage.clear();
    return (
        <div

            style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 1000,
            }
            }
            className={"fullPage"}>
            <div
                style={{
                    height: "80vh",
                    width: "80vw",
                }}
            >

                <div className="register">
                    <div className="col-1-auth">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                            }}
                        >
                            <img
                                style={{
                                    width: "250px",
                                    height: "150px",
                                }}
                                src={logo}
                                alt=""
                            />
                            <div>
                                <h2>Log In</h2>
                                <span
                                    style={{
                                        color: "#adb5bd",
                                    }}
                                >
                    Welcome back!
                  </span>
                            </div>
                        </div>

                        <Formik initialValues={initialValues} onSubmit={onSubmit}>
                            {(props) => (
                                <Form noValidate>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Field
                                            as={TextField}
                                            name="first_name"
                                            label="First Name"
                                            variant="outlined"
                                            required
                                            InputLabelProps={{shrink: true}}
                                            style={{marginTop: "10px", width: "100%"}}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {/*<Field*/}
                                        {/*    as={TextField}*/}
                                        {/*    type={"password"}*/}
                                        {/*    name="account_number"*/}
                                        {/*    label="Account Number"*/}
                                        {/*    required*/}
                                        {/*    variant="outlined"*/}
                                        {/*    InputLabelProps={{shrink: true}}*/}
                                        {/*    style={{marginTop: "10px", width: "100%"}}*/}
                                        {/*/>*/}

                                        <Field
                                            as={TextField}
                                            type={type}
                                            name="account_number"
                                            label="Account Number"
                                            variant="outlined"
                                            required
                                            InputLabelProps={{shrink: true}}
                                            style={{
                                                width: "100%",
                                                marginTop: "10px",
                                                cursor: "pointer",
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment
                                                        onClick={() => {
                                                            if (type === "password") {
                                                                setType("text");
                                                                setIcon(<VisibilityOffOutlined/>);
                                                            } else {
                                                                setType("password");
                                                                setIcon(<VisibilityOutlined/>);
                                                            }
                                                        }}
                                                        position="end"
                                                    >
                                                        {icon}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                    </div>

                                    <br/>

                                    <LoadingButton
                                        type={"submit"}
                                        color="primary"
                                        loading={loading}
                                        loadingPosition="end"
                                        endIcon={<Login/>}
                                        variant="contained"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        Log In
                                    </LoadingButton>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    {/*<div className="col-2-auth">*/}
                    {/*    <img src={require("../../assets/img1.jpg")} alt=""/>*/}
                    {/*</div>*/}
                </div>

            </div>
        </div>
    );
}
