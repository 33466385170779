export const KEY = "MarkKieru@55";
export var loginState = false;

//Live API
// export const ApiDetails = "https://172.105.244.19:8080/";
export const ApiDetails = "https://api.pegasus-pms.co.ke/api/";

//Local API
// export const ApiDetails = "//0.0.0.0:8080/";
export const LocalPublicApiDetails = "https://41.90.101.242:8080/";

//Callback Endpoint
//http://85.159.214.103:8080/pegasus/visionary/payments/
